import { useLocalization } from "@progress/kendo-react-intl";
import DrawMetaTags from "app-styled/metatags";
import { HomeWrapper } from "./home.styled";
import loadable from "@loadable/component";
import { useSelector } from "react-redux";
import { getSocialUserFetchingStatusFromReducer } from "app-module/auth/auth.selectors";

const MainBanner = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "./mainBanner")
);
const TreatmentReviewHome = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "./treatmentReviewHome")
);
const PatientFeaturesHome = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "./patientFeatures")
);
const TreatmentPlaning = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "./treatmentPlaning")
);
const SmylorOverview = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "./smylorOverview")
);
const DentistRegistration = loadable( 
  () => import(/*webpackChunkName: "pkg_____home"*/ "./dentistRegistration")
);
const SmylorDentalClinicWrapper = loadable( 
  () => import(/*webpackChunkName: "pkg_____home"*/ "./dentalClinicDetails")
);
const DentalOwner = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "./dentalOwner")
);
const Footer = loadable(
  () => import(/*webpackChunkName: "pkg_____home"*/ "../../modules/footer")
);

const HomeComponent = () => {
  const localization = useLocalization();
  const socialUserFetching = useSelector(
    getSocialUserFetchingStatusFromReducer
  );
  const keywords = localization.toLanguageString(
    "meta_homepage_keywords",
    "smylor,smylor dentist, dentist, Dentist, Dentist office, Dentist near me, Emergency dentist, Pediatric dentist, Family dentist, Kids dentist, Dental clinic emergency, dental, patient, online booking, dental clinic near me"
  );
  const metaDescription = localization.toLanguageString(
    "meta_homepage_description",
    "Smylor is a leading dental marketplace for finding, booking and reviewing dental practice treatments. Includes the ability to request a treatment from 100s of dentists in a local area."
  );
  const metaImageUrl = `${process.env.REACT_APP_ROOT_URL}/assets/img/banner-1920x1280.jpg`;
  const metaTitle = localization.toLanguageString(
    "meta_homepage_title",
    "Dental Treatments Marketplace | Smylor"
  );
  const metaPageUrl = process.env.REACT_APP_ROOT_URL;

  return (
    <>
      <div className={socialUserFetching ? "k-overlay" : ""} />
      <HomeWrapper>
        <DrawMetaTags
          description={metaDescription}
          imageUrl={metaImageUrl}
          title={metaTitle}
          pageUrl={metaPageUrl}
          keywords={keywords}
        ></DrawMetaTags>
        <MainBanner />
        <TreatmentReviewHome/>
        <PatientFeaturesHome/>
        <TreatmentPlaning />
        <SmylorOverview />
        <DentistRegistration />
        <SmylorDentalClinicWrapper />
        {/* <DownloadApp /> */}
        {/* <QAndA /> */}
        {/* <DentalOwner /> */}
        <Footer />
      </HomeWrapper>
    </>
  );
};

export default HomeComponent;

